<template>
	<div>
		<search-header></search-header>
		<div v-if="detail" class="aw-container-wrap">
			<div class="container border rounded mb-4">
				<div class="row">
					<div  class="col-sm-12 col-md-9 aw-main-content">
						<div v-if="getTag" class="aw-mod aw-topic-bar">
							<div class="tag-bar clearfix">
								<span class="topic-tag">
									<router-link :to="'/ask?id=' + getTag.code" class="text">
										<b-icon icon="tag"></b-icon>
										{{getTag.name}}
									</router-link>
								</span>
							</div>
						</div>
						<!-- end 话题bar -->
						<div class="aw-mod aw-question-detail aw-item">
							<div class="mod-head">
								<h1>{{detail.title}}</h1>
							</div>

							<div class="mod-body">
								<div class="content">
									<p  v-if="detail.content" v-html="detail.content"  class="mt-1"></p>
								</div>
							</div>
							<div class="mod-footer">
								<div class="meta">
									<span class="f-14">来源：农村综合产权服务中心</span>
									<span class="text-gray-777 f-14">{{detail.createdAt}}</span>
									<div class="float-right" style="position:relative;">
										<a @click="isShareShow = !isShareShow" href="javascript:;"
											class="text-gray-777 share-toggle">
											<i class="custom-icon icon-share"></i>
											<span class="ml-1">分享</span>
										</a>
										<div class="clearfix"></div>
										<div v-if="isShareShow" class="aw-dropdown shareout float-right">
                      <share class="ml-2" :config="shareConfig"></share>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="aw-mod aw-question-comment ">
							<div class="mod-head">
								<ul class="nav nav-tabs aw-nav-tabs active">
									<h2>{{detail.answerNum}} 个回答</h2>
								</ul>
							</div>
							<div class="mod-body aw-feed-list">
								<div v-for="m,j in list.records" :key="j" class="aw-item">
									<div class="mod-head">
										<!-- 用户头像 -->
										<div class="float-left">
											<img v-if="m.headPic != null && m.headPic != ''" class="aw-user-img rounded" :src="m.headPic">
											<img v-else class="aw-user-img rounded" src="@/assets/img/headpic.png">
										</div>
										<!-- end 用户头像 -->
										<div class="title">
											<img v-if="m.official" class="float-left mr-1 width-20" src="@/assets/icon/official.png"/>
											<div class="f-14 text-gray-777">
												<span>{{m.anonymous ? '匿名' : (m.nicName == null ? '游客': m.nicName)}}</span>
												<a href="javascript:;" @click="onDelete(m.id,j)" v-if="m.uid == user.uid" class="float-right ml-2">删除</a>
												<a v-if="(detail.adoptAnswerId == '' || detail.adoptAnswerId == null) && detail.uid == user.uid" @click="adoptAnswer(m.id)" href="javascript:;" class="float-right">采纳该回答</a>
												<span v-if="detail.adoptAnswerId == m.id" class="float-right text-theme">已采纳</span>
											</div>
										</div>
									</div>
									<div class="mod-body clearfix">
										<!-- 评论内容 -->
										<div class="markitup-box f-16" v-html="m.content">
										</div>
									</div>
									<div class="mod-footer">
										<div class="meta clearfix">
											<span class="text-gray-777 f-14 float-right">{{m.createdAt}}</span>
										</div>
									</div>
								</div>

								<div v-if="list && list.total > list.size" class="mb-3 mt-3 mr-1">
									<div class="clearfix text-center">
										<b-pagination @change="onPageChange" class="float-right" :total-rows="list.total" :per-page="list.size" v-model="para.current"></b-pagination>
									</div>
								</div>
							</div>
						</div>
						
						<!-- end 问题详细模块 -->
						<div class="mt-3" v-if="token">
							<div class="editor border ml-4 mr-4">
								<div ref="toolbar" class="toolbar"></div>
								<div ref="editor" class="text" style="height: 160px;"></div>
							</div>
							<div class="mt-2 ml-4 mr-4 clearfix">
								<b-form-checkbox
									v-model="aPara.anonymous"
									class="text-gray-777 float-left mt-1 mr-2"
									value="true"
									unchecked-value="false">
										匿名
								</b-form-checkbox>
								<b-button @click="addAnswer" variant="success" class="float-left w-25">提交回答</b-button>
							</div>
						</div>
						<div v-else class="aw-mod f-16 mt-3">
							<p class="text-center">
								要回答问题请先
								<router-link class="text-theme" to="/login" target="_blank">登录</router-link>
								或
								<router-link class="text-theme" to="/register" target="_blank">注册</router-link></p>
						</div>
					</div>
					<!-- 侧边栏 -->
					<div class="col-md-3 aw-side-bar float-left">
						<!-- 发起人 -->
						<div class="aw-mod">
							<div class="mod-body">
								<div class="clearfix mt-2">
									<div class="float-left">
										<h3 class="f-16 mt-1">发起人</h3>
									</div>
									<div class="float-right ml-2">
										<img v-if="detail.headPic == null || detail.headPic == ''" class="rounded-circle width-30" src="~@/assets/img/headpic.png">
										<img v-else class="rounded-circle width-30" :src="detail.headPic">
										<span class="f-14">
											{{detail.nicName ? detail.nicName : '游客'}}
										</span>
									</div>
								</div>
							</div>
						</div>
						<div class="aw-mod">
							<div class="mod-head">
								<h3 class="f-16">问答社区平台</h3>
							</div>
							<div class="mod-body">
								<div class="w-100 text-center">
									<div class="clearfix mt-2">
										<div class="float-left">
											<h3 class="f-14 text-gray-777 mt-1">已帮助用户</h3>
										</div>
										<div class="float-right ml-2 mb-2">
											<span class="f-18 text-warning">
												{{totalSelve}} +
											</span>
										</div>
										<router-link to="/ask/add" target="_blank">
											<b-button variant="success" class="w-100">我要提问</b-button>
										</router-link>
									</div>
								</div>
							</div>
						</div>
						<div class="aw-mod">
							<div class="mod-head">
								<h3 class="f-16">热门问题</h3>
							</div>
							<div class="clearfix mt-2" v-for="m,i in hotQuestionList" :key="i">
								<div class="float-left">
									<img v-if="m.headPic != null && m.headPic != ''" class="rounded width-40" :src="m.headPic">
									<img v-else class="rounded-circle width-40" src="@/assets/img/headpic.png">
								</div>
								<div class="float-left pl-2" style="width: 200px;line-height: 1.3;">
									<router-link :to="'/ask/detail?id=' + m.id" target="_blank" class="f-14">
										{{m.nicName}}: {{m.title}}
									</router-link>
									<div class="f-14 text-black-50 text-right"><b>{{m.answerNum}}</b> 人回答</div>
								</div>
							</div>
						</div>

						<div v-if="relevantQuestionList" class="aw-mod">
							<div class="mod-head">
								<h3 class="f-16">相关问题</h3>
							</div>
							<div class="mod-body f-14">
								<ul>
									<li v-for="m,i in relevantQuestionList" :key="i"><router-link :to="'/ask/detail?id=' + m.id" target="_blank">{{m.title}}</router-link></li>
								</ul>
							</div>
						</div>
					</div>
					<!-- end 侧边栏 -->
				</div>
			</div>
		</div>
		<map-footer></map-footer>
		
	</div>
</template>

<script>
	import E from 'wangeditor'
	import {
		mapGetters
	} from 'vuex'
	export default {
		data() {
			return {
				isShareShow: false,
				editor: null,
				detail: null,
				classList: [],
				totalSelve: 0,
				aPara: {
					anonymous: 'false',
					content: '',
					questionId:  '',
					userType: '',
					userName: ''
				},
        shareConfig: {
          url                 : 'http://www.51cqjy.com/#/ask/detail?id=', // 网址，默认使用 window.location.href
          source              : '', // 来源（QQ空间会用到）, 默认读取head标签：<meta name="site" content="http://overtrue" />
          title               : '', // 标题，默认读取 document.title 或者 <meta name="title" content="share.js" />
          description         : '', // 描述, 默认读取head标签：<meta name="description" content="PHP弱类型的实现原理分析" />
          image               : '', // 图片, 默认取网页中第一个img标签
          sites               : ['qq', 'weibo','wechat'], // 启用的站点
          wechatQrcodeTitle   : '微信扫一扫：分享', // 微信二维码提示文字
          wechatQrcodeHelper  : '<p>微信里点“发现”，扫一下</p><p>二维码便可将本文分享至朋友圈。</p>'
        },
				para: {
					param: {
						questionId: '',
						status: true
					},
					size:10,
					current:1
				},
				list: [],
				hotQuestionList:[],
				relevantQuestionList:[],
				detail: null
			}
		},
		computed: {
			...mapGetters(['token','user']),
			getTag() {
				let m = this.classList.find(x=> x.code == this.detail.classId);
				if(m) {
					let c = m.children.find(x=> x.code == this.detail.childClassId);
					if(c) {
						return c
					} else {
						return m
					}
				}
				return null
			}
		},
		methods: {
			getClass() {
				this.$http.post(this.$api.ask.getClass,{searchParam:''}).then(res => {
					this.classList = res.result;
				})
			},
			onPageChange(i) {
				this.para.current = i;
				this.getList();
			},
			getDetail() {
				this.$http.get(this.$api.ask.addQuestion + '/' + this.aPara.questionId).then(res => {
					if(res.code == 1) {
					  debugger;
						this.detail = res.result;

            this.shareConfig.url+=this.detail.id;
           /* this.shareConfig.title = this.detail.title;*/
            if (res.result.answerNum<=0){
              this.shareConfig.title = this.detail.title;
            }else if (res.result.adoptAnswerId!=null){
              this.shareConfig.title=res.result.answers.find(x=> x.id=res.result.adoptAnswerId).questionContent;
            }else{
              this.shareConfig.title=res.result.answers[0].questionContent;
            }
          this.shareConfig.description = this.detail.title;
            this.shareConfig.source = this.detail.title == '' ? '农村综合产权交易服务平台' : this.detail.title;
            this.shareConfig.image = this.detail.thumbnail;
            const regex = new RegExp(`<img [^>]*src=['"]([^'"]+)[^>]*>`, "gi");
            let _this = this;
            this.detail.content = this.detail.content.replace(
                regex,
                function (match, capture) {
                  return capture.includes("http://") ||
                  capture.includes("https://")
                      ? match
                      : match.replace(
                          capture,
                          _this.$global.picBaseUrl + capture.replace("../", "")
                      );
                }
            );

						this.$nextTick(()=>{
							this.seteditor()
						});
						this.getRelevantQuestion();
						this.getHotQestion();
					}
				})
			},
			getList() {
				this.$http.post(this.$api.ask.getAnswerList,this.para).then(res => {
					if(res.code == 1) {
						this.list = res.result;
					}
				})
			},
			onDelete(id, i) {
				this.$dialog.alert({
					title: "温馨提示", 
					message: "是否删除该条回答?", 
					showCancelButton: true 
				}).then(() => {
					this.$http.delete(this.$api.ask.addAnswer + "/" + id).then((res) => {
						if (res.code == 1) {
							this.list.records.splice(i, 1);
							if (this.list.records.length == 0) {
								this.onPageChange(1);
							}
						}
					});
				})
			},
			adoptAnswer(id) {
				this.$http.post(this.$api.ask.adoptAnswer + '/' + id + '/').then(res => {
					if(res.code == 1) {
						this.detail.adoptAnswerId = id;
					} else {
						this.$model.toast(res.reason,2000);
					}
				}).catch(error => {
					this.$model.toast(error.reason,2000);
				})
			},
			addAnswer() {
				if(this.aPara.content.trim() == '') {
					this.$model.toast('请填写您要回答的内容',2000);
					return false;
				}
				this.$http.post(this.$api.ask.addAnswer, this.aPara).then(res => {
					if(res.code  == 1) {
						this.$model.toast('回答成功',2000);
						this.editor.txt.clear()
						this.aPara.content = '';
						this.onPageChange(1);
					} else {
						this.$model.toast(res.reason,2000);
					}
				}).catch(error => {
					this.$model.toast(error.reason,2000);
				})
			},
			getHotQestion() {
				this.$http.post(this.$api.ask.getHotQuestion, {top: 5, classId: this.detail.classId, childClassId: this.detail.childClassId}).then(res => {
					if(res.code == 1) {
						this.hotQuestionList = res.result;
					}
				})
			},
			getRelevantQuestion() {
				this.$http.post(this.$api.ask.getQuestionList, 	{param: {
						classId: this.detail.classId,
						childClassId: this.detail.childClassId
					},
					size: 5,
					current: 1}).then(res => {
					if(res.code == 1) {
						this.relevantQuestionList = res.result.records;
					}
				});
			},
			getTotalSolve() {
				this.$http.post(this.$api.ask.getTotalSolve).then(res => {
					this.totalSelve = res.result;
				})
			},
			seteditor() {
				this.editor = new E(this.$refs.toolbar, this.$refs.editor)
				this.editor.config.menus = [
					'head',
					'bold',
					'fontSize',
					'underline',
					'strikeThrough',
					'indent',
					'foreColor',
					'backColor',
					'undo',
					'redo',
				]
				this.editor.config.zIndex = 9
				// 创建富文本编辑器
				this.editor.config.onchangeTimeout = 1000
				this.editor.config.onchange = (html) => {
					this.aPara.content = html // 绑定当前逐渐地值
				}
				this.editor.create()
			}
		},
		created() {
			this.aPara.questionId = this.$route.query.id;
			this.para.param.questionId = this.$route.query.id;
			this.getDetail();
			this.getList();
			this.getClass();
			this.getTotalSolve();
		}
	}
</script>

<style scoped>
	.aw-container-wrap {
		margin-top: 30px;
	}

	.aw-main-content {
		padding: 0 0 20px;
		border-right: 1px solid #e6e6e6;
	}

	/* 分类小标签 */
	.aw-side-bar {
		margin-left: -1px;
		padding: 20px;
		border-left: 1px solid #e6e6e6;
	}

	.aw-topic-bar {
		margin: 20px 20px 0;
	}

	.aw-topic-bar .close {
		display: none;
	}

	.aw-topic-bar.active .topic-tag .text {
		border-radius: 4px 0 0 4px;
	}

	.aw-topic-bar .topic-tag {
		float: left;
		margin: 0 10px 10px 0;
	}

	.topic-tag a {
		float: left;
	}

	.topic-tag .text {
		display: inline-block;
		height: 26px;
		line-height: 16px;
		padding: 5px 10px;
		background-color: #66FF66;
		font-size: 16px;
		color: #fff;
		border-radius: 4px;
	}

	.topic-tag .text:hover,
	.topic-tag .text:active {
		background-color: #00cc00;
	}

	/* tabs切换样式 */
	.aw-nav-tabs {
		position: relative;
		z-index: 1;
		min-height: 63px;
		min-height: 32px\9;
		margin: 0;
		padding: 30px 20px 0;
		border-color: #e6e6e6;
	}

	.aw-nav-tabs.active>li {
		float: right;
	}

	.aw-nav-tabs>li {
		margin: 0 4px 0 0;
		font-size: 14px;
	}

	.aw-nav-tabs>li>a {
		line-height: 30px;
		padding: 0 13px;
		background-color: #fff;
		border: none;
		cursor: pointer;
	}

	.aw-nav-tabs>li>a:hover {
		border: none;
		border-bottom: 2px solid #155faa;
		background-color: #fff;
		color: #333;
	}

	.aw-nav-tabs>li.active a {
		line-height: 30px;
		background-color: #fff;
		border: none;
		border-bottom: 2px solid #155faa;
		font-weight: bold;
		color: #333;
	}

	.aw-nav-tabs>li.active a:hover,
	.aw-nav-tabs>li.active a:focus,
	.aw-nav-tabs>li>a:focus {
		border: none;
		border-bottom: 2px solid #155faa;
		background-color: #fff;
	}

	.aw-nav-tabs h2 {
		position: absolute;
		top: 16px;
		left: 20px;
		margin: 0;
		font-size: 20px;
	}

	/* 问题详情页 */
	.aw-question-detail {
		margin-bottom: 30px;
		padding: 10px 20px 0;
	}

	.aw-question-detail>.mod-head {
		position: relative;
		padding-bottom: 14px;
		border-bottom: 1px solid #f5f5f5;
	}

	.aw-question-detail .mod-head h1 {
		max-width: 500px;
		margin: 0;
		font-size: 20px;
		line-height: 30px;
	}

	.aw-question-detail .mod-head .operate {
		position: absolute;
		right: 0;
		top: 0;
	}

	.aw-question-detail .mod-head .operate>a {
		margin: 0 5px 0 0;
	}

	.aw-question-detail .content {
		padding: 10px 0;
	}

	.aw-question-detail .meta {
		min-height: 20px;
		line-height: 20px;
		font-size: 12px;
	}

	.aw-question-detail .meta>span,
	.aw-question-detail .meta>a,
	.aw-question-detail .meta>div {
		margin-right: 15px;
	}

	.aw-question-detail .meta .badge-info {
		margin: 0;
	}

	.aw-question-detail .more-operate {
		position: relative;
		margin: 0 !important;
	}

	.aw-question-detail .more-operate>a,
	.aw-question-detail .more-operate em {
		margin-left: 15px;
	}


	.aw-question-detail .markitup-box br:first-child,
	.aw-question-comment .markitup-box br:first-child {
		display: none;
	}


	.aw-feed-list {
		padding: 0 20px;
	}

	.aw-feed-list .aw-load-more-content {
		margin: 20px 0;
	}

	.aw-feed-list .aw-item {
		position: relative;
		padding: 14px 0;
		border-bottom: 1px solid #f5f5f5;
	}

	.aw-feed-list .aw-item:last-child {
		border-bottom: none;
	}

	.aw-feed-list .aw-item.active {
		margin: 0 -20px;
		padding: 14px 20px;
		background-color: #fffed1;
	}

	.aw-feed-list .aw-item .mod-head,
	.aw-feed-list .aw-item .mod-body,
	.aw-feed-list .aw-item .mod-footer {
		position: relative;
		width: 100%;
		padding-left: 50px;
	}

	.aw-feed-list .aw-item .mod-head {
		z-index: 1;
	}

	.aw-feed-list .aw-item .mod-footer {
		min-height: 28px;
		line-height: 28px;
		margin-top: 10px;
		font-size: 12px;
	}

	.aw-feed-list .aw-item .aw-user-img {
		position: absolute;
		left: 0;
		top: 3px;
		width: 40px;
		height: 40px;
	}

	.aw-feed-list .aw-item .aw-user-img img {
		width: 40px;
		height: 40px;
	}

	.aw-feed-list .aw-item .close {
		display: none;
	}

	.aw-feed-list .aw-item:hover .more-operate,
	.aw-feed-list .aw-item:hover .mod-head span.float-right {
		display: block;
	}

	.aw-feed-list .aw-item h4,
	.aw-feed-list .aw-item p {
		margin: 0;
	}

	.aw-feed-list .meta i,
	.aw-question-detail .meta i {
		font-size: 16px;
		vertical-align: -2px;
	}


	.aw-feed-list .more-operate>a {
		float: left;
		height: 28px;
		line-height: 28px;
		margin-left: 15px;
		font-size: 12px;
	}

	.aw-feed-list .more-operate .btn-group {
		margin-left: 15px;
	}

	.aw-feed-list .more-operate .btn-group .share-toggle {
		box-shadow: none;
	}

	.aw-feed-list .operate {
		display: inline-block;
		float: left;
		background-color: #f5f5f5;
		border-radius: 4px;
	}

	.aw-feed-list .operate+.operate {
		margin-left: 5px;
	}

	.aw-feed-list .operate a {
		float: left;
		height: 28px;
		padding: 0 10px;
		color: #999;
		line-height: 28px;
		text-decoration: none;
	}

	.aw-feed-list .operate a+a {
		border-left: 1px solid #ebebeb;
	}

	.aw-feed-list .operate a.agree {
		border-radius: 6px;
	}

	.aw-feed-list .operate a:hover,
	.aw-feed-list .operate a.active {
		background-color: #00cc00;
		color: #fff;
	}

	.aw-feed-list .operate a:hover i,
	.aw-feed-list .operate a.active i {
		color: #fff;
	}

	.aw-feed-list .operate a.disabled {
		background-color: #fff;
		border: 1px solid #f5f5f5;
		border-radius: 4px;
	}

	.aw-feed-list .operate b {
		font-weight: 500;
	}

	/* 侧边栏公共部分 */
	.aw-side-bar .aw-mod {
		padding: 20px 0;
		border-top: 1px solid #e2e2e2;
	}

	.aw-side-bar .aw-mod:first-child {
		padding-top: 0px;
		border-top: none;
	}

	.aw-side-bar .aw-mod .mod-head {
		padding-bottom: 5px;
	}

	.aw-side-bar .aw-mod .mod-head a {
		font-size: 12px;
	}

	.aw-side-bar .aw-mod h3 {
		margin-bottom: 0;
	}

	.aw-side-bar .aw-mod p {
		margin-bottom: 0;
	}

	.aw-side-bar .aw-mod p.signature {
		max-height: 34px;
		overflow: hidden;
		color: #666;
	}

	.aw-side-bar .aw-mod ul li {
		padding: 3px 0;
	}

	@media screen and (max-width:1200px) {
		.aw-container-wrap {
			padding: 0 15px 0 15px;
			width: 1140px;
			max-width: 1140px;
		}

		.aw-container-wrap .row,
		.container {
			width: 1110px;
			max-width: 1110px;
		}
	}
</style>
